@font-face {
    font-family: BigCaslon;
    font-display: swap;
    src: url(/site-assets/fonts/BigCaslon-Medium-01.ttf);
}

@font-face {
    font-family: ReplicaBold;
    font-display: swap;
    src: url(/site-assets/fonts/ReplicaBold.otf);
}

@font-face {
    font-family: Lora;
    font-display: swap;
    src: url(/site-assets/fonts/Lora-Italic.ttf);
}

@font-face {
    font-family: "Helvetica Neue";
    font-display: swap;
    src: url(/site-assets/fonts/Helvetica-Neue-LT-Std-55-Roman_22526.ttf);
}

@font-face {
    font-family: "Helvetica Neue";
    font-display: swap;
    src: url(/site-assets/fonts/Helvetica-Neue-Bold_22498.ttf);
    font-weight: bold;
}