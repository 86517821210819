@import 'styles/reset.scss';
@import 'styles/shared.scss';

html {
    font-size: 46%;
    backface-visibility: hidden;

    body {
        padding: 0;
        margin: 0;
        font-family: $main-font-family; 
        font-weight: $font-normal;

        .container, .tank {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            overflow: hidden;

            a {
                text-decoration: none;
                color: unset;

                &:hover {
                    color: $colour-gray;
                }
            }
        }
    }
}

@media screen and (min-width: $phone-small) {
    html {
        font-size: 50%;
    }
}

@media screen and (min-width: $phone-large) {
    html {
        font-size: 55%;
    }
}

@media screen and (min-width: $phone-large) {
    html {
        font-size: 57%;
    }
}

@media screen and (min-width: $tablet-small) {
    html {
        font-size: 60%;
    }
}

@media screen and (min-width: $tablet-large) {
    html {
        font-size: 62.5%;
    }
}