// box shadow states

$box-shadow-on: 3px 3px 3px 0px rgba(0,0,0,0.5);
$box-shadow-off: 0px 0px 9px 0px rgba(0,0,0,0);
$box-shadow-menu: 2px 2px 3px 0px rgba(0, 0, 0, 0.36);
$arrow-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.36);

// font sizes

// details pages
$h1-font-size: 6.4rem;
$h2-font-size: 5rem;
$h2-about-font-size: 4rem;
$h3-font-size: 2rem;

$h2-font-emotional-drivers: 3.6rem;

$h1-font-size-mobile: 4.5rem;
$h1-font-size-mobile-case-study: 3.6rem;
$h2-font-size-mobile: 3rem;
$h2-font-size-small-mobile: 2.4rem;

// content pages
$h1-content-size: 4.5rem;
$h2-content-size: 2rem;
$content-size-massive: 5rem;
$content-size-case-study-highlights: 4.6rem;
$content-size-larger: 4rem;
$content-size-large: 3rem;
$content-size-normal: 2rem;
$content-size-normal-small: 1.8rem;
$content-size-headline: 1.8rem;
$content-size-small: 1.4rem;
$quote-content-size: 4.6rem;

$content-size-massive-mobile: 4rem;
$content-size-larger-mobile: 3.5rem;
$content-size-large-mobile: 3rem;
$content-size-normal-mobile: 2.2rem;
$content-size-small-mobile: 1.6rem;

// font weights

$font-normal: 400;
$font-bold: 500;
$font-heavy: 700;

// font and bg colours
// $main-font-family: "Helvetica Neue", Helvetica, "Arial Narrow", "Arial", "Lucida Grande", sans-serif;
$case-study-highlight-font-family: "BigCaslon", "Book Antiqua","Palatino Linotype",Georgia,serif;
// $homebrew-logo-font: "ReplicaBold", Helvetica, "Arial Black", "Arial Bold", sans-serif;

// 2022 update fonts
$main-font-family: "Helvetica Neue", Helvetica, "Arial Narrow", "Arial", "Lucida Grande", sans-serif;
$highlight-font-family:  "Helvetica Neue", Helvetica, "Arial Narrow", "Arial", "Lucida Grande", sans-serif;
$highlight-italic-font-family: Lora, "Palatino Linotype", Palatino, Georgia, serif;
$homebrew-logo-font: "ReplicaBold", Helvetica, "Arial Black", "Arial Bold", sans-serif;

$colour-white: #fff;
$colour-black: #000;
$colour-gray: #bec6c3;
$colour-dark-pink: #b13848;
$colour-pink: #edc4b8;

a:-webkit-any-link {
    color: inherit;
}

// letter spacing, line-height, text margins etc

$line-height-normal: 2.4rem;
$line-height-h1: 9rem;
$line-height-h2: 6rem;
$line-height-highlight: 2.2rem;
$line-height-content: 3rem;
$line-height-content-highlight: 3.6rem;
$line-height-content-highlight-line: 3.4rem; 
$line-height-content-massive-line: 5.4rem;
$line-height-highlight-italic: 4rem;
$line-height-quote: 5rem;

$line-height-mobile: 3.4rem;
$line-height-mobile-content: 3.4rem;
$line-height-mobile-highlight: 4rem;
$line-height-mobile-h1: 4.4rem;
$line-height-mobile-h2: 4rem;
$line-height-mobile-work: 3.4rem;
$line-height-mobile-large: 5rem;
$line-height-mobile-small: 2.2rem;
$line-height-mobile-about-tiny: 2.6rem;

$line-height-h2-emotional-drivers: 4.4rem;
$line-height-h2-emotional-drivers-mobile: 4rem;
$line-height-h2-emotional-drivers-mobile-small: 3.4rem;
$line-height-h2-emotional-drivers-mobile-tiny: 3rem;

$letter-spacing-menu: 0.05rem;
$letter-spacing-normal: 0.01rem;

// header
$letter-spacing-content-header: 0.04rem;
$line-height-content-header: 4.6rem;
$line-height-content-header-large: 6rem;

$highlight-margin-bottom: 2.5rem;

// padding

$padding: 2rem;
$padding-content-width: 6rem;

// animation timings

$quick-anim-timing: 0.25s;
$default-anim-timing: 0.5s;
$full-anim-timing: 0.7s;
$off-screen-anim-timing: 0.5s;
$dbl-off-screen-anim-timing: 1s;
$anim-delay: 0.15s;
$long-anim-delay: 0.5s;

$default-ease: ease-out;

// text fade up for text fade-in
$base-delay: 0.75s;
$base-duration: 0.5s;
$text-delay: 0.75s;
$text-ease: cubic-bezier(0.57, 0.07, 0.25, 0.98);
$text-fade-up: $base-duration $text-ease $base-delay forwards text-fade-up-keys;

$case-study-image-transition-time: 0.1s;
                    
@keyframes text-fade-up-keys {
    0% {
        transform: translateY(2vh);
        opacity: 0;
    }
    100% {
        transform: translateY(0vh);
        opacity: 1;
    }
}

// media query breakpoints 
$phone-tiny: 321px;
$phone-small: 500px;
$phone-large: 700px;
$tablet-small: 900px;
$tablet-large: 1100px;
$laptop: 1300px; 

$phone-small-height: 570px;