@import 'styles/shared.scss';
@import 'styles/app.scss';
@import 'styles/menu.scss';

html {
    backface-visibility: hidden;

    body {
        padding: 0;
        margin: 0;

        .container, .tank {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            overflow: hidden;
            
            .loader {
                color: $colour-black;
                transform: translate(-50%, -50%);
                left: 50%;
                width: 80%;
                top: 50%;
                position: absolute;
                text-align: center;
                z-index: 5;
                opacity: 1;

                svg {
                    width: 80%;

                    &.fade-up {
                        opacity: 0;
                        animation: 1s $text-ease 0s forwards text-fade-up-keys;
                    }
                }

                &.fade-out {
                    opacity: 0;
                    z-index: 0;
                    transition: opacity 0.5s linear, z-index 0.5s linear;
                }

                &.hide {
                    opacity: 0;
                    z-index: 0;
                }
            }
        }
    }
}