@import "styles/shared.scss";

@mixin menu-start($height-calc, $margin) {
  transform: translateX(-50%)
    translateY(calc(-100% + #{$height-calc} + #{$margin}));
}
@mixin menu-end($height-calc, $margin) {
  transform: translateX(0) translateY(calc(100% - #{$height-calc} - #{$margin}));
}

$homebrew-logo-size: 1.8rem;
$menu-height: 200px;
$logo-top-margin: 26px;
$logo-top-margin-peek: 52px;
$text-pointer: default;

$homebrew-mobile-size: 3.2rem;
$logo-top-margin-mobile: 30px;

.menu-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-102%);
  text-align: center;
  z-index: 10;

  &.active {
    @include menu-start($homebrew-logo-size, $logo-top-margin);
    // transition: transform 0.5s ease-out 1.5s;
  }

  &.out {
    .touchMenu {
      display: none;
    }
  }

  &.peek-a-boo {
    animation: peek-menu-keys 10s 5s infinite;

    .main-menu .inside {
      visibility: hidden;
    }
  }

  &.peek-a-boo-long {
    animation: peek-menu-keys 10s 10s infinite;

    .main-menu .inside {
      visibility: hidden;
    }
  }

  @keyframes peek-menu-keys {
    0% {
      @include menu-start($homebrew-logo-size, $logo-top-margin);
    }
    2% {
      @include menu-start($homebrew-logo-size, $logo-top-margin-peek);
    }
    4% {
      @include menu-start($homebrew-logo-size, $logo-top-margin);
    }
    6% {
      @include menu-start($homebrew-logo-size, $logo-top-margin-peek);
    }
    8% {
      @include menu-start($homebrew-logo-size, $logo-top-margin);
    }
    100% {
      @include menu-start($homebrew-logo-size, $logo-top-margin);
    }
  }

  .touchMenu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 1000;
  }

  &.out .touchMenu {
    display: none;
  }

  .touchDeactivate {
    display: none;
  }

  &.out .touchDeactivate {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    transform: scale(50);
    z-index: -1;
  }

  .main-menu {
    width: auto;
    display: inline-block;
    padding: 10px 0;
    background-color: $colour-black;
    left: 50%;
    top: -1px;
    text-align: center;
    //    box-shadow: $box-shadow-menu;
    font-size: 1.6rem;
    letter-spacing: $letter-spacing-menu;
    text-align: left;
    margin: 0 auto;
    transform: translateY(0px);
    transition: transform 0.25s, background-color 0.25s 0.25s, color 0.25s 0.6s;

    .inside {
      max-width: 15rem;

      .MarketingSidekick {
        padding: 2px 0;
      }
    }

    ul li,
    .homebrew {
      a:hover {
        text-decoration: none;
        cursor: pointer;
      }
    }

    ul {
      font-weight: $font-bold;
      padding: 0 2px;
      margin: 5px;

      li {
        padding: 2px 0;
        margin: 5px;
        color: $colour-white;
        transition: color 0.25s 0.3s;
        cursor: $text-pointer;
        font-weight: $font-heavy;

        a {
          text-decoration: none;
          font-weight: $font-normal;
          padding-block: 3px;

          &:hover {
            color: $colour-gray;
            transition: none;
          }
        }
      }
    }

    ul.homebrew {
      font-size: $homebrew-logo-size;
      letter-spacing: 0.1rem;
      padding: 0;
      margin: $logo-top-margin 0 0;
      font-family: $homebrew-logo-font;
      font-weight: $font-normal;
      text-align: center;

      li {
        padding: 0;
        margin: 0;
        cursor: $text-pointer;
        text-transform: uppercase;
        padding: 0 12px;
        font-weight: inherit;

        a {
          &:hover {
            color: $colour-gray;
          }

          &.active:hover {
            cursor: pointer;
          }
        }
      }
    }

    &.black {
      background-color: $colour-white;

      ul {
        li {
          color: $colour-black;
          transition: color 0.25s 0.3s;

          a {
            text-decoration: none;
            font-weight: $font-normal;

            &:hover {
              color: $colour-gray;
              transition: none;
            }
          }
        }
      }

      ul.homebrew li {
        color: $colour-black;
        font-weight: $font-normal !important;

        a:hover {
          color: $colour-gray;
          cursor: pointer;
        }
      }
    }

    &.rollover {
      @include menu-end($homebrew-logo-size, $logo-top-margin);
    }
  }
}

@media screen and (max-width: $phone-large) {
  .menu-container {
    .main-menu .inside {
      max-width: 23rem;

      .MarketingSidekick {
        letter-spacing: 0px;
        padding: 0;
      }
    }

    &.peek-a-boo {
      animation: peek-menu-keys 10s 5s infinite;

      .main-menu .inside {
        visibility: hidden;
      }
    }

    @keyframes peek-menu-keys {
      0% {
        @include menu-start($homebrew-mobile-size, $logo-top-margin-mobile);
      }
      2% {
        @include menu-start($homebrew-mobile-size, $logo-top-margin-peek);
      }
      4% {
        @include menu-start($homebrew-mobile-size, $logo-top-margin-mobile);
      }
      6% {
        @include menu-start($homebrew-mobile-size, $logo-top-margin-peek);
      }
      8% {
        @include menu-start($homebrew-mobile-size, $logo-top-margin-mobile);
      }
      100% {
        @include menu-start($homebrew-mobile-size, $logo-top-margin-mobile);
      }
    }

    .touchMenu {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      z-index: 1000;
    }

    &.out .touchMenu {
      display: none;
    }

    .touchDeactivate {
      display: none;
    }

    &.out .touchDeactivate {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      transform: scale(50);
      z-index: -1;
    }

    &.active {
      @include menu-start($homebrew-mobile-size, $logo-top-margin-mobile);
      //transition: transform 0.5s ease-out 1.5s;
    }

    .main-menu {
      font-size: $homebrew-mobile-size;
      padding: 14px 8px;

      ul li {
        margin: 15px 5px;
      }

      ul.homebrew {
        font-size: $homebrew-mobile-size;
        margin: $logo-top-margin-mobile 0 0;
      }

      &.rollover {
        @include menu-end($homebrew-mobile-size, $logo-top-margin-mobile);
      }
    }
  }
}

@media screen and (max-height: $phone-small-height) {
  .menu-container .main-menu ul {
    li {
      margin: 10px 5px;
    }

    &.homebrew {
      margin-top: 20px;
    }
  }
}
